import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';

function HelpDesk(props) {

    const form = useRef();

    const navigate = useNavigate();
    const [guest, setGuest] = useState({
        guest_name: "",
        guest_birth_date: "",
        guest_gender: "",
        guest_phone_no: "",
        guest_email: "",
        guest_address: "",
        guest_category: "",
        guest_message: ""
    });

    const handleChange = (event) => {
        setGuest(values => ({ ...guest, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const guestData = {
            guest_name: guest.guest_name,
            guest_birth_date: guest.guest_birth_date,
            guest_gender: guest.guest_gender,
            guest_phone_no: guest.guest_phone_no,
            guest_email: guest.guest_email,
            guest_address: guest.guest_address,
            guest_category: props.category,
            guest_message: guest.guest_message,
        }
        const response = fetch(
            "https://surakshasocialwelfare-default-rtdb.firebaseio.com/guest_data.json",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(guestData)
            }
        );

        emailjs.sendForm('service_88b3kad', 'template_4s1dlos', form.current, 'l6X-Wi3LhAqNDnrXt')

        if (response) {
            props.showAlert("Thankyou for contacting us. Our team will reach out to you soon. Have a Nice Day !");
            navigate('/alert');
        }
    }
    
    return (
        <div>
            <form ref={form} onSubmit={handleSubmit} className="fw-bold p-2 rounded shadow" style={{ backgroundColor: "yellowgreen" }}>
                <div className="form-group m-3">
                    <label htmlFor="guest_name" className="form-label">Full Name</label>
                    <input type="text" className="form-control" id="guest_name" name="guest_name" placeholder="Enter your name" onChange={handleChange} value={guest.guest_name} required />
                </div>
                <div className="form-group m-3">
                    <label htmlFor="guest_birth_date" className="form-label">Date of Birth</label>
                    <input type="date" className="form-control" id="guest_birth_date" name="guest_birth_date" onChange={handleChange} value={guest.guest_birth_date} required />
                </div>
                <div className="form-group m-3">
                    <label htmlFor="guest_gender" className="form-label">Gender</label>
                    <br />
                    <div className="form-check form-check-inline">
                        <label className="form-check-label" htmlFor="guest_gender">Male</label>
                        <input className="form-check-input" type="radio" name="guest_gender" id="guest_gender" onChange={handleChange} value="Male" required />
                    </div>
                    <div className="form-check form-check-inline">
                        <label className="form-check-label" htmlFor="guest_gender">Female</label>
                        <input className="form-check-input" type="radio" name="guest_gender" id="guest_gender" onChange={handleChange} value="Female" required />
                    </div>
                    <div className="form-check form-check-inline">
                        <label className="form-check-label" htmlFor="guest_gender">Transgender</label>
                        <input className="form-check-input" type="radio" name="guest_gender" id="guest_gender" onChange={handleChange} value="Transgender" required />
                    </div>
                </div>
                <div className="form-group m-3">
                    <label htmlFor="guest_phone_no" className="form-label">Phone Number</label>
                    <input type="text" className="form-control" id="guest_phone_no" name="guest_phone_no" placeholder="Enter your contact no" onChange={handleChange} value={guest.guest_phone_no} required />
                </div>
                <div className="form-group m-3">
                    <label htmlFor="guest_email" className="form-label">Email Address</label>
                    <input type="email" className="form-control" id="guest_email" name="guest_email" placeholder="Enter your email id" onChange={handleChange} value={guest.guest_email} required />
                </div>
                <div className="m-3">
                    <label htmlFor="guest_address" className="form-label">Address</label>
                    <input type="text" className="form-control" id="guest_address" name="guest_address" placeholder="Enter your complete address" onChange={handleChange} value={guest.guest_address} required />
                </div>
                <div className="m-3">
                    <label htmlFor="guest_category" className="form-label">Help Category</label>
                    <input type="text" className="form-control" id="guest_category" name="guest_category" onChange={handleChange} value={`${props.category}`} disabled/>
                </div>
                <div className="m-3">
                    <label htmlFor="guest_message" className="form-label">Problem</label>
                    <textarea rows="3" className="form-control" id="guest_message" name="guest_message" placeholder="Enter your message here" onChange={handleChange} value={guest.guest_message} required />
                </div>
                <div className="form-group m-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default HelpDesk