import React from 'react'
import StoryCard from './components/StoryCard'
import story1 from './components/images/Stories/1.jpeg'
import story2 from './components/images/Stories/2.jpg'
import story3 from './components/images/Stories/3.jpg'
import story4 from './components/images/Stories/4.jpg'
import story5 from './components/images/Stories/5.jpg'
import story6 from './components/images/Stories/6.jpg'
import story7 from './components/images/Stories/7.jpg'
import story8 from './components/images/Stories/8.jpg'
import story9 from './components/images/Stories/9.jpg'
import story10 from './components/images/Stories/10.jpg'
import story11 from './components/images/Stories/11.jpg'
import story12 from './components/images/Stories/12.jpg'
import story13 from './components/images/Stories/13.jpg'

function Stories() {
  return (
    <div className="container">
      <h1 className="text-center mt-4">OUR STORIES</h1>
      <hr/>
      <StoryCard storyImage={story13} storyDescription="As a part of our commitment to the Digital India initiative, we realized the need to have an online presence that could reach a wider audience and make our services more accessible to people across the country. We were fortunate to work with two highly skilled individuals, Swati Suman and Dhyanish Khanna, who came up and worked closely with our ngo to ensure that the website clearly reflects our vision and mission."/>
      <StoryCard storyImage={story12} storyDescription="Digital education has become increasingly important in today's world, particularly in light of the COVID-19 pandemic and the need for remote learning. We conducted a digital education awareness program in over 20,000 villages spread across Bihar, in collaboration with 50 NGOs."/>
      <StoryCard storyImage={story10} storyDescription="During times of crisis or hardship, it is often essential to come together and collaborate to provide support to those in need. In one such instance, we were able to provide assistance to individuals and families in need with the help of the Superintendent of Police (SP) and District Magistrate (DM) of the area."/>
      <StoryCard storyImage={story7} storyDescription="In Gopalganj district, we conducted a survey among women in five villages to gather their opinions on the need for a new hospital facility. We recognized that women's perspectives are often overlooked in decision-making processes related to healthcare, and we wanted to ensure that their voices were heard."/>
      <StoryCard storyImage={story9} storyDescription="Dr. Abhishek Ranjan, an ex-AIIMS doctor, led an initiative to provide free health checkups and medicines to flood victims across villages. The floods had a significant impact on the health and wellbeing of individuals and communities, and we recognized the urgent need to provide support to maintain good health."/>
      <StoryCard storyImage={story1} storyDescription="Assisted around 300 elderly persons in starting their pension benefits. We worked tirelessly to guide them through the filing process and ensured that they met all statutory guidelines. Additionally, we actively communicated with relevant administrations to facilitate the process and make it as seamless as possible for them."/>
      <StoryCard storyImage={story11} storyDescription="In many parts of the country, there are various government schemes that are available to individuals and families in need, but lack of awareness and education about these schemes can often prevent people from accessing them. We took the initiative to educate people about different monetary schemes, such as Indira Aawas Yojana, and their legal eligibility and rights to avail of such benefits."/>
      <StoryCard storyImage={story3} storyDescription="We recognized that many of these families were struggling to make ends meet. As part of our ongoing efforts to support marginalized communities, we recently distributed food packages to more 100 families residing in the slums. The experience was incredibly humbling, and we were touched by the resilience and gratitude of the families we supported."/>
      <StoryCard storyImage={story8} storyDescription="During the floods, we led an initiative to provide daily meals to over 300 families affected by the disaster. We recognized that access to basic necessities such as food was limited for many individuals and families, and we wanted to provide some relief during these difficult times."/>
      <StoryCard storyImage={story4} storyDescription="In the midst of the COVID-19 pandemic, Dr. Kumar Vishwas emerged as a visionary figure and played a crucial role in supporting patients who were struggling to access medical care. Dr. Vishwas recognized that many COVID patients were unable to leave their homes due to quarantine restrictions or limited mobility, which made it difficult for them to access the medication they needed to recover. To address this issue, he organized the delivery of medicine packages to more than 150 COVID patients."/>
      <StoryCard storyImage={story5} storyDescription="In Kamalpur village, we organized health monitoring activities for individuals who were suspected of having COVID-19. The monitoring activities included measuring oxygen levels, monitoring blood pressure, and checking pulse rates. We worked closely with a team of healthcare professionals to ensure that the monitoring activities were conducted safely and effectively."/>
      <StoryCard storyImage={story6} storyDescription="In the slum areas of Nawada panchayat, we led a team to conduct health monitoring activities for individuals who were suspected of having COVID-19. These activities included monitoring oxygen levels, blood pressure, and pulse rates, which were crucial indicators in identifying COVID-19 symptoms and determining the severity of the illness."/>
      <StoryCard storyImage={story2} storyDescription="In response to the COVID-19 pandemic, we took it upon ourself to distributed more than 1000 cylinders to COVID patients in need. As the virus spread rapidly, there was a severe shortage of medical resources, and many patients were struggling to access the oxygen they needed to survive. Through our efforts, we were able to help alleviate some of the burden on the healthcare system and ensured that critically ill patients had access to the oxygen they needed."/>
    </div>
  )
}

export default Stories