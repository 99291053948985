import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Award01 from './images/Awards/Award01.jpg'
import Award02 from './images/Awards/Award02.jpg'
import Award03 from './images/Awards/Award03.jpg'

function AwardSlider() {
    return (
        <div className="container my-4 p-4">
            <h3 className="text-center bg-success bg-opacity-10 rounded p-2 fw-bold mb-4">Awards & Recognitions</h3>
            <Swiper slidesPerView={3} spaceBetween={30} centeredSlides={true} autoplay={{ delay: 2000, disableOnInteraction: false }} pagination={{ clickable: true }} navigation={true} modules={[Autoplay, Navigation, Pagination]} className="mySwiper"  breakpoints={{0:{slidesPerView:1},480:{slidesPerView:2},760:{slidesPerView:3}}}>
                <SwiperSlide><a href={Award01} target="_blank" rel='noreferrer'><img src={Award01} alt="" className="rounded shadow" /></a></SwiperSlide>
                <SwiperSlide><a href={Award01} target="_blank" rel='noreferrer'><img src={Award02} alt="" className="rounded shadow" /></a></SwiperSlide>
                <SwiperSlide><a href={Award01} target="_blank" rel='noreferrer'><img src={Award03} alt="" className="rounded shadow" /></a></SwiperSlide>
            </Swiper>
        </div>
    );
}

export default AwardSlider;