import React from 'react'

function StoryCard(props) {
    return (
        <div class="card m-4 shadow">
            <div className="row">
                <div className="col-md-3">
                    <img src={props.storyImage} class="card-img-top" alt="..." />
                </div>
                <div className="col-md-9">
                    <div class="card-body">
                        <p class="card-text">{props.storyDescription}</p>
                        {/* <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoryCard