import React from 'react'

function TeamCard(props) {
    return (
        <div className="col-md-2 my-4">
            <div className="card bg-gradient shadow h-100" style={{backgroundColor:"yellowgreen"}}>
                <div className="card-image m-2">
                    <img src={props.memberPhoto} className="card-img-top rounded-circle" alt="..." />
                </div>
                <div className="card-body text-center">
                    <h6 className="card-text">{props.memberName}</h6>
                    <small className="card-text">{props.memberProfile}</small>
                </div>
            </div>
        </div>
    )
}

export default TeamCard