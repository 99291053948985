import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './Home';
import About from './About';
import Mission from './Mission';
import Team from './Team';
import JoinUs from './JoinUs';
import { useState } from 'react';
import Alert from './Alert';
import Health from './Health';
import Education from './Education';
import Employment from './Employment';
import WomenEmpowerment from './WomenEmpowerment';
import ConstitutionalRemedies from './ConstitutionalRemedies';
import SocialAwareness from './SocialAwareness';
import ScrollToTop from './ScrollToTop';
import Donate from './Donate';
import Contact from './Contact';
import Stories from './Stories';

function App() {

  const [alert, setAlert] = useState(null);
  const showAlert = (message) => {
    setAlert(message);
  }

  return (
    <div className="bg-light bg-gradient" style={{ minHeight:"100vh"}}>
      <BrowserRouter>
      <ScrollToTop/>
        <Header/>
        <NavBar />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/mission' element={<Mission />} />
          <Route path='/stories' element={<Stories />} />
          <Route path='/team' element={<Team />} />
          <Route path='/contact' element={<Contact showAlert={showAlert}/>} />
          <Route path='/joinUs' element={<JoinUs showAlert={showAlert}/>} />
          <Route path='/donate' element={<Donate showAlert={showAlert}/>} />

          <Route path='/health' element={<Health showAlert={showAlert}/>} />
          <Route path='/education' element={<Education showAlert={showAlert}/>} />
          <Route path='/employment' element={<Employment showAlert={showAlert}/>} />
          <Route path='/womenEmpowerment' element={<WomenEmpowerment showAlert={showAlert}/>} />
          <Route path='/constitutionalRemedies' element={<ConstitutionalRemedies showAlert={showAlert}/>} />
          <Route path='/socialAwareness' element={<SocialAwareness showAlert={showAlert}/>} />
          <Route path='/alert' element={<Alert alert={alert} />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
