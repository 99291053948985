import React from 'react'

function Footer() {
  return (
    <div className="container-fluid bg-dark bg-gradient text-center text-light p-4">
      <div className="row">
        <div className="col-md-4 p-4 text-start">
          <h3>Contact</h3>
          <p>Phone No: +91 94304 00328, +91 99343 66769</p>
          <p>Email ID: hope@surakshasocial.in</p>
          <p>Address: Suraksha Social Welfare Trust, Near Panchayat Bhawan, Nawada, Barauli, Gopalganj (Bihar)</p>
        </div>
        <div className="col-md-4 p-4">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.090812467759!2d84.5359801150889!3d26.3882535888227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39931f8bd9a224cb%3A0xff7591a989b6479f!2z4KS44KWB4KSw4KSV4KWN4KS34KS-IOCkuOCli-CktuCksiDgpLXgpYfgpLLgpKvgpYfgpK_gpLAg4KSf4KWN4KSw4KS44KWN4KSfIOCkleCkvuCksOCljeCkr-CkvuCksuCkrw!5e0!3m2!1sen!2sin!4v1678101182442!5m2!1sen!2sin" className="rounded" width="100%" height="100%" title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="col-md-4 p-4 text-start">
            <h2 className="mt-4 mt-lg-0">Follow Us</h2>
            <div className="row mt-4">
              <div className="col-2" >
                <a href="https://wa.me/919430400328" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp fa-xl text-success"></i></a>
              </div>
              <div className="col-2" >
                <a href="https://www.facebook.com/profile.php?id=100081263571268" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook fa-xl text-primary"></i></a>
              </div>
              <div className="col-2" >
                <a href="/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram fa-xl text-primary"></i></a>
              </div>
              <div className="col-2" >
                <a href="/" target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube fa-xl text-danger"></i></a>
              </div>
              <div className="col-2" >
                <a href="/" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter fa-xl text-info"></i></a>
              </div>
            </div>
        </div>
      </div>
      <hr />
      <small>Copyright &#169; 2023 | Suraksha Social Welfare Trust - All Rights Reserved</small>
    </div>
  )
}

export default Footer