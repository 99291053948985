import React from 'react'

function ProductCard(props) {
    return (
        <div className="col-md-2 my-4">
            <div className="card bg-dark bg-gradient h-100 mx-2">
                <img src={props.productImage} className="card-img-top" alt="..." />
                <div className="card-body text-center">
                    <h6 className="card-text text-light">{props.productName}</h6>
                </div>
            </div>
        </div>
    )
}

export default ProductCard