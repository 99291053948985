import React from 'react'
import HelpDesk from './components/HelpDesk'

function SocialAwareness(props) {
    return (
        <div className="conatiner-fluid">
            <div className="row mx-1 bg-light">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">Social Awareness</h1>
                    <hr />
                    <ul className="my-3">
                        <li className="my-3">Suraksha Social Welfare Trust is a non-profit organization dedicated to raising public awareness about critical issues facing our society.</li>
                        <li className="my-3">We believe that through education and advocacy, we can create positive change and promote social justice.</li>
                        <li className="my-3">Our mission is to raise public awareness about important social, environmental, and political issues.</li>
                        <li className="my-3">We strive to promote informed decision-making and responsible civic engagement.</li>
                        <li className="my-3">We offer a range of programs, including:</li>
                        <ul>
                            <li>Public Education Campaigns</li>
                            <li>Media Advocacy</li>
                            <li>Grassroots Organizing</li>
                        </ul>
                        <li className="my-3">We work with communities to raise awareness about issues such as climate change, social justice, human rights, and public health.</li>
                        <li className="my-3">Our team comprises passionate and dedicated advocates, community organizers, and support staff. They work tirelessly to ensure that our programs are effective and impactful.</li>
                    </ul>
                </div>
                <div className="col-md-6 p-4">
                    <HelpDesk category="Social Awareness" showAlert={props.showAlert} />
                </div>
            </div>
        </div>
    )
}

export default SocialAwareness