import React from 'react'
import { Link } from 'react-router-dom';

function ServiceCard(props) {
    return (
        <div className="col-md-4 my-4 px-lg-4">
            <div className="card bg-light bg-gradient shadow text-dark mx-3 h-100">
                <img src={props.cardImage} className="card-img-top" alt={props.category} />
                <div className="card-body">
                    <h4 className="card-title">{props.category}</h4>
                    <hr/>
                    <p className="card-text">{props.categoryDescription}</p>
                    <Link to={`/${props.categoryLink}`} className="btn btn-success">Get Help</Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard