import React from 'react'
import HelpDesk from './components/HelpDesk'

function Education(props) {
    return (
        <div className="conatiner-fluid">
            <div className="row mx-1 bg-light">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">Education</h1>
                    <hr />
                    <ul className="my-3">
                        <li className="my-3">Suraksha Social Welfare Trust is a non-profit organization dedicated to improving the health and wellbeing of people in need.</li>
                        <li className="my-3">We believe that access to quality healthcare is a fundamental right that should be available to everyone, regardless of their socio-economic status.</li>
                        <li className="my-3">Our mission is to provide healthcare services to underserved communities and promote public health awareness through education and advocacy.</li>
                        <li className="my-3">We strive to empower individuals and communities to take control of their health and well-being.</li>
                        <li className="my-3">We offer a range of programs, including:</li>
                        <ul>
                            <li>Formal Education</li>
                            <li>Vocational Training</li>
                            <li>Life Skills Development</li>
                        </ul>
                        <li className="my-3">We work with children and youth from disadvantaged backgrounds, providing them with the tools and resources they need to succeed in life.</li>
                        <li className="my-3">Our team comprises qualified and experienced educators, trainers, and support staff. They work tirelessly to ensure that our programs are effective and impactful.</li>
                    </ul>
                </div>
                <div className="col-md-6 p-4">
                    <HelpDesk category="Education" showAlert={props.showAlert} />
                </div>
            </div>
        </div>
    )
}

export default Education