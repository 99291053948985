import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import joinUs from './components/images/joinUs1.jpg'

function JoinUs(props) {

    const navigate = useNavigate();
    const [member, setMember] = useState({
        member_name: "",
        member_birth_date: "",
        member_gender: "",
        member_phone_no: "",
        member_email: "",
        member_district: "",
        member_pincode: "",
        member_category: "",
        member_skills: ""
    });

    const handleChange = (event) => {
        setMember(values => ({ ...member, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const memberData = {
            member_name: member.member_name,
            member_birth_date: member.member_birth_date,
            member_gender: member.member_gender,
            member_phone_no: member.member_phone_no,
            member_email: member.member_email,
            member_district: member.member_district,
            member_pincode: member.member_pincode,
            member_category: member.member_category,
            member_skills: member.member_skills,
        }
        const response = fetch(
            "https://surakshasocialwelfare-default-rtdb.firebaseio.com/member_data.json",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(memberData)
            }
        );
        if (response) {
            props.showAlert("We would like to extend our sincere thanks to you for showing interest in joining our cause. Your willingness to devote your time, energy and skills towards making a positive impact in people's lives is truly inspiring.");
            navigate('/alert');
        }
    }

    return (
        <div className="container-fluid">
            <div className="row" style={{backgroundImage:`url(${joinUs})`, backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                <div className="col-md-2 p-2">
                </div>
                <div className="col-md-8 p-4 bg-gradient rounded">
                    <h2 className="text-center bg-success bg-opacity-10 rounded p-2">Share Your Details To Become A Part Of Our Beautiful Journey</h2>
                    <hr/>
                    <form onSubmit={handleSubmit} className="fw-bold">
                        <div className="form-group m-3">
                            <label htmlFor="member_name" className="form-label badge bg-success">Full Name</label>
                            <input type="text" className="form-control" id="member_name" name="member_name" placeholder="Enter your name" onChange={handleChange} value={member.member_name} required />
                        </div>
                        <div className="form-group m-3">
                            <label htmlFor="member_birth_date" className="form-label badge bg-success">Date of Birth</label>
                            <input type="date" className="form-control" id="member_birth_date" name="member_birth_date" onChange={handleChange} value={member.member_birth_date} required />
                        </div>
                        <div className="form-group m-3">
                            <label htmlFor="member_gender" className="form-label badge bg-success">Gender</label>
                            <br />
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" htmlFor="member_gender">Male</label>
                                <input className="form-check-input" type="radio" name="member_gender" id="member_gender" onChange={handleChange} value="Male" required />
                            </div>
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" htmlFor="member_gender">Female</label>
                                <input className="form-check-input" type="radio" name="member_gender" id="member_gender" onChange={handleChange} value="Female" required />
                            </div>
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" htmlFor="member_gender">Transgender</label>
                                <input className="form-check-input" type="radio" name="member_gender" id="member_gender" onChange={handleChange} value="Transgender" required />
                            </div>
                        </div>
                        <div className="form-group m-3">
                            <label htmlFor="member_phone_no" className="form-label badge bg-success">Phone Number</label>
                            <input type="text" className="form-control" id="member_phone_no" name="member_phone_no" placeholder="Enter your contact no" onChange={handleChange} value={member.member_phone_no} required />
                        </div>
                        <div className="form-group m-3">
                            <label htmlFor="member_email" className="form-label badge bg-success">Email Address</label>
                            <input type="email" className="form-control" id="member_email" name="member_email" placeholder="Enter your email id" onChange={handleChange} value={member.member_email} required />
                        </div>
                        <div className="m-3">
                            <label htmlFor="member_district" className="form-label badge bg-success">District</label>
                            <input type="text" className="form-control" id="member_district" name="member_district" placeholder="Enter your complete address" onChange={handleChange} value={member.member_district} required />
                        </div>
                        <div className="m-3">
                            <label htmlFor="member_pincode" className="form-label badge bg-success">Pincode</label>
                            <input type="number" className="form-control" id="member_pincode" name="member_pincode" placeholder="Enter your pin code" onChange={handleChange} value={member.member_pincode} required />
                        </div>
                        <div className="form-group m-3">
                            <label htmlFor="member_category" className="form-label badge bg-success">Area of Interest</label>
                            <select className="form-select" id="member_category" name="member_category" onChange={handleChange} value={member.member_category} required >
                                <option value="NULL">Select Category</option>
                                <option value="Health">Health</option>
                                <option value="Education">Education</option>
                                <option value="Employment">Employment</option>
                                <option value="Street Child">Women Empowerment</option>
                                <option value="Constitutional Remedies">Constitutional Remedies</option>
                                <option value="Human Rights">Social Awareness</option>
                            </select>
                        </div>
                        <div className="m-3">
                            <label htmlFor="member_skills" className="form-label badge bg-success">Skills</label>
                            <input type="text" className="form-control" id="member_skills" name="member_skills" placeholder="Enter your skills" onChange={handleChange} value={member.member_skills} required />
                        </div>
                        <div className="form-group m-3">
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="col-md-2 p-2">
                </div>
            </div>
        </div>
    )
}

export default JoinUs