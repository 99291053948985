import React from 'react'

function Mission() {
  return (
    <div className="container-fluid p-2 p-lg-5 bg-gradient" style={{ backgroundColor: "yellowgreen" }}>
      <div className="m-4">
        <h1>Mission</h1>
        Through our services in education, health, employment, women empowerment, social awareness, and legal advice, we aim to empower individuals and communities to build a better future for themselves and for all.
        <ul>
          <li className="my-1">Our NGO is dedicated to creating a better world for all.</li>
          <li className="my-1">We provide services in education, health, employment, women empowerment, social awareness, and legal advice.</li>
          <li className="my-1">Our mission is to empower individuals and communities to achieve their full potential.</li>
          <li className="my-1">We believe in the power of education, health, and employment to create a brighter future.</li>
          <li className="my-1">We are committed to empowering women through education, training, and support.</li>
          <li className="my-1">We seek to raise awareness and promote action on important social issues.</li>
          <li className="my-1">We offer legal support and advice to protect the rights and interests of individuals and communities.</li>
          <li className="my-1">Through our services, we aim to empower individuals and communities to build a better future for themselves and for all.</li>
        </ul>
      </div>
    </div>
  )
}

export default Mission