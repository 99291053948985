import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "./components/ProductCard";
import BloodDonation from './components/images/BloodDonation.jpg'
import WheatFlour from './components/images/Products/WheatFlour.jpg'
import Rice from './components/images/Products/Rice.jpg'
import Pulses from './components/images/Products/Pulses.jpg'
import FruitsAndVegetables from './components/images/Products/FruitsAndVegetables.jpg'
import OilAndGhee from './components/images/Products/OilAndGhee.jpg'
import StationaryItems from './components/images/Products/StationaryItems.jpg'
import Books from './components/images/Products/Books.jpg'
import Computer from './components/images/Products/Computer.jpeg'
import Blankets from './components/images/Products/Blankets.jpg'
import SanitaryPads from './components/images/Products/SanitaryPads.jpeg'
import WheelChair from './components/images/Products/WheelChair.jpg'
import HandSticks from './components/images/Products/HandSticks.jpg'
import QR from './components/images/QR.jpg'

function Donate(props) {

  const navigate = useNavigate();
  const [donor, setDonor] = useState({
    donor_name: "",
    donor_gender: "",
    donor_phone_no: "",
    donor_email: "",
    donor_district: "",
    donor_pincode: "",
    donor_blood_group: ""
  });

  const handleChange = (event) => {
    setDonor(values => ({ ...donor, [event.target.name]: event.target.value }));
  }

  const handleSubmit = async (event) => {
      event.preventDefault();
      const donorData = {
        donor_name: donor.donor_name,
        donor_gender: donor.donor_gender,
        donor_phone_no: donor.donor_phone_no,
        donor_email: donor.donor_email,
        donor_district: donor.donor_district,
        donor_pincode: donor.donor_pincode,
        donor_blood_group: donor.donor_blood_group
      }
      const response = fetch(
          "https://surakshasocialwelfare-default-rtdb.firebaseio.com/donor_data.json",
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(donorData)
          }
      );
      if (response) {
          props.showAlert("We would like to express our heartfelt gratitude to show your willingness to donate blood. Your selfless act of kindness is truly appreciated.");
          navigate('/alert');
      }
  }

  return (
    <div className="container-fluid">
      <div className="row text-center m-2 px-4">
        <div className="p-4 bg-light rounded shadow">
          <h3>Your Generosity Can Make A Difference. Donate Today To Support Our Cause !</h3>
          <hr />
          <div className="row">
            <div className="col-md-6 p-2">
              <img src={QR} alt="" height="150vh" />
              <br />
              <small>Scan the QR to donate now</small>
            </div>
            <div className="col-md-6 p-3 text-lg-start">
              <p><b>Account Name: </b>Suraksha Social Welfare Trust</p>
              <p><b>Bank Name: </b>HDFC Bank</p>
              <p><b>IFSC Code: </b>HDFC0002773</p>
              <p><b>Account No: </b>50200080064273</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-6 p-4">
          <div className="p-4 bg-light rounded shadow h-100">
            <h4 className="text-center">Donate Products To Support Our Cause</h4>
            <hr />
            <p>Donate essential products to our NGO and make a difference in the lives of those in need.You can send the product to our Head office i.e.</p>
            <p><b>Suraksha Social Welfare Trust, Near Panchayat Bhawan, Nawada, Barauli, Gopalganj (Bihar)</b></p>
          <a href="#product_list"><button className="btn btn-success">View Products</button></a>
          </div>
        </div>
        <div className="col-md-6 p-4">
          <div className="p-4 bg-light rounded shadow h-100" style={{backgroundColor:"yellowgreen"}}>
            <h4>Give Blood, Save Lives. Be A Hero Today</h4>
            <hr />
            <p>Your blood donation can help save lives in your community - donate today with our NGO.</p>
            <br/><br/><br/>
            <a href="#blood_donation"><button className="btn btn-success">Submit Details</button></a>
          </div>
        </div>
      </div>
      
      <div className="row p-4" id="product_list" style={{backgroundColor:"yellowgreen"}}>
        <h1 className="text-center my-4">Our Current Requirements</h1>
        <hr />
        <ProductCard productImage={WheatFlour} productName="Wheat Flour" />
        <ProductCard productImage={Rice} productName="Rice" />
        <ProductCard productImage={Pulses} productName="Pulses" />
        <ProductCard productImage={FruitsAndVegetables} productName="Fruits and Vegetables" />
        <ProductCard productImage={OilAndGhee} productName="Oil and Ghee" />
        <ProductCard productImage={StationaryItems} productName="Stationary Items" />
        <ProductCard productImage={Books} productName="Books(Used or New)" />
        <ProductCard productImage={Computer} productName="Computers" />
        <ProductCard productImage={Blankets} productName="Blankets" />
        <ProductCard productImage={WheelChair} productName="Wheel Chairs" />
        <ProductCard productImage={HandSticks} productName="Hand Sticks" />
        <ProductCard productImage={SanitaryPads} productName="Sanitary Pads" />
      </div>

      <div className="row" id="blood_donation" style={{ backgroundImage: `url(${BloodDonation})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className="col-md-6 p-4 text-center">
          <h1>Blood Donation</h1>
          <hr />
        </div>
        <div className="col-md-6 mt-2 px-2 bg-gradient rounded">
          <form onSubmit={handleSubmit} className="fw-bold">
            <div className="form-group m-3">
              <label htmlFor="donor_name" className="form-label badge bg-dark">Full Name</label>
              <input type="text" className="form-control" id="donor_name" name="donor_name" placeholder="Enter your name" onChange={handleChange} value={donor.donor_name} required />
            </div>
            <div className="form-group m-3">
              <label htmlFor="donor_gender" className="form-label badge bg-dark">Gender</label>
              <br />
              <div className="form-check form-check-inline">
                <label className="form-check-label" htmlFor="donor_gender">Male</label>
                <input className="form-check-input" type="radio" name="donor_gender" id="donor_gender" onChange={handleChange} value="Male" required />
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label" htmlFor="donor_gender">Female</label>
                <input className="form-check-input" type="radio" name="donor_gender" id="donor_gender" onChange={handleChange} value="Female" required />
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label" htmlFor="donor_gender">Transgender</label>
                <input className="form-check-input" type="radio" name="donor_gender" id="donor_gender" onChange={handleChange} value="Transgender" required />
              </div>
            </div>
            <div className="form-group m-3">
              <label htmlFor="donor_phone_no" className="form-label badge bg-dark">Phone Number</label>
              <input type="text" className="form-control" id="donor_phone_no" name="donor_phone_no" placeholder="Enter your donor no" onChange={handleChange} value={donor.donor_phone_no} required />
            </div>
            <div className="form-group m-3">
              <label htmlFor="donor_email" className="form-label badge bg-dark">Email Address</label>
              <input type="email" className="form-control" id="donor_email" name="donor_email" placeholder="Enter your email id" onChange={handleChange} value={donor.donor_email} required />
            </div>
            <div className="m-3">
              <label htmlFor="donor_district" className="form-label badge bg-dark">District</label>
              <input type="text" className="form-control" id="donor_district" name="donor_district" placeholder="Enter your complete address" onChange={handleChange} value={donor.donor_district} required />
            </div>
            <div className="m-3">
              <label htmlFor="donor_pincode" className="form-label badge bg-dark">Pincode</label>
              <input type="number" className="form-control" id="donor_pincode" name="donor_pincode" placeholder="Enter your pin code" onChange={handleChange} value={donor.donor_pincode} required />
            </div>
            <div className="form-group m-3">
              <label htmlFor="donor_blood_group" className="form-label badge bg-dark">Blood Group</label>
              <select className="form-select" id="donor_blood_group" name="donor_blood_group" onChange={handleChange} value={donor.donor_blood_group} required >
                <option value="NULL">Select your blood group</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
              </select>
            </div>
            <div className="form-group m-3">
              <button type="submit" className="btn btn-dark">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Donate