import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

function NavBar() {

    const [show, setShow] = useState(false);
    
    return (
        <nav className="navbar navbar-expand-lg sticky-top shadow navbar-light" style={{backgroundColor:"olivedrab"}}>
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShow(!show)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${show ? "show" : ""}`} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item mx-lg-5">
                            <NavLink className="nav-link fw-bold" to="/home" onClick={() => setShow(!show)}>Home</NavLink>
                        </li>
                        <li className="nav-item mx-lg-5">
                            <NavLink className="nav-link fw-bold" to="/stories" onClick={() => setShow(!show)}>Stories</NavLink>
                        </li>
                        <li className="nav-item mx-lg-5">
                            <NavLink className="nav-link fw-bold" to="/team" onClick={() => setShow(!show)}>Our Team</NavLink>
                        </li>
                        <li className="nav-item dropdown mx-lg-5">
                            <NavLink className="nav-link fw-bold dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Our Services
                            </NavLink>
                            <ul className="dropdown-menu border-0" style={{backgroundColor:"olivedrab"}}>
                                <li><Link className="dropdown-item fw-bold" to="/health" onClick={() => setShow(!show)}>Health</Link></li>
                                <li><Link className="dropdown-item fw-bold" to="/education" onClick={() => setShow(!show)}>Education</Link></li>
                                <li><Link className="dropdown-item fw-bold" to="/employment" onClick={() => setShow(!show)}>Employment</Link></li>
                                <li><Link className="dropdown-item fw-bold" to="/womenEmpowerment" onClick={() => setShow(!show)}>Women Empowerment</Link></li>
                                <li><Link className="dropdown-item fw-bold" to="/constitutionalRemedies" onClick={() => setShow(!show)}>Constitutional Remedies</Link></li>
                                <li><Link className="dropdown-item fw-bold" to="/socialAwareness" onClick={() => setShow(!show)}>Social Awareness</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item mx-lg-5">
                            <NavLink className="nav-link fw-bold" to="/contact" onClick={() => setShow(!show)}>Contact</NavLink>
                        </li>
                        <li className="nav-item mx-lg-5">
                            <NavLink className="nav-link fw-bold" to="/joinUs" onClick={() => setShow(!show)}>Join Us</NavLink>
                        </li>
                        <li className="nav-item mx-lg-5">
                            <NavLink className="nav-link fw-bold" to="/donate"><button className="btn btn-sm btn-light my-0 px-3" onClick={() => setShow(!show)}><b>Donate</b></button></NavLink>
                        </li>
                    </ul>
                {/* <button className="btn btn-primary me-lg-1"><Link className="nav-link" to="/joinUs">Join Us</Link></button>
                <button className="btn btn-primary mx-lg-1"><Link className="nav-link" to="/donate">Donate</Link></button> */}
                </div>
            </div>
        </nav>
    )
}

export default NavBar