import React from 'react'
import TeamCard from './components/TeamCard'
import NitaKumari from './components/images/Team/NitaKumari.jpg'
import DhanuPratapSingh from './components/images/Team/DhanuPratapSingh.jpg'
import JhunnaSrivastav from './components/images/Team/JhunnaSrivastav.jpg'
import SubhashKumar from './components/images/Team/SubhashKumar.jpg'
import AmodKumar from './components/images/Team/AmodKumar.jpg'
import KumarShivam from './components/images/Team/KumarShivam.jpg'
import AnupamShrivastav from './components/images/Team/AnupamShrivastav.jpg'
import SudhanshuTrivedi from './components/images/Team/SudhanshuTrivedi.jpg'
import SwatiSuman from './components/images/Team/SwatiSuman.jpg'
import DhyanishKhanna from './components/images/Team/DhyanishKhanna.jpg'
import DeepuRai from './components/images/Team/DeepuRai.jpg'
import AkhileshKumar from './components/images/Team/AkhileshKumar.jpg'
import JaiPrakash from './components/images/Team/JaiPrakash.jpg'
import PradeepKumar from './components/images/Team/PradeepKumar.jpg'
import RaviRanjan from './components/images/Team/RaviRanjan.jpg'
import AshishRanjan from './components/images/Team/AshishRanjan.jpg'
import NitishKumar from './components/images/Team/NitishKumar.jpg'
import ShubhamKumar from './components/images/Team/ShubhamKumar.jpg'
import AnupKumar from './components/images/Team/AnupKumar.jpg'
import VivekPathak from './components/images/Team/VivekPathak.jpg'
import NileshMishra from './components/images/Team/NileshMishra.jpg'
import RanjitSrivastva from './components/images/Team/RanjitSrivastva.jpg'
import SachinSingh from './components/images/Team/SachinSingh.jpg'
import ArjunSingh from './components/images/Team/ArjunSingh.jpg'
import ShubhamSingh from './components/images/Team/ShubhamSingh.jpg'
import VishalKumar from './components/images/Team/VishalKumar.jpg'

function Team() {
    return (
        <div className="container mx-auto">
            <div className="row m-4">
            <h1 className="text-center shadow rounded p-2">Pillars Of Our Organization</h1>
                <TeamCard memberPhoto={NitaKumari} memberName="Nita Kumari" memberProfile="Social Worker"/>
                <TeamCard memberPhoto={DhanuPratapSingh} memberName="Dhanu Pratap Singh" memberProfile="Electrical Engineer"/>
                <TeamCard memberPhoto={JhunnaSrivastav} memberName="Jhunna Shrivastav" memberProfile="Human Rights Specialist"/>
                <TeamCard memberPhoto={SubhashKumar} memberName="Subhash Kumar" memberProfile="Social Worker"/>
                <TeamCard memberPhoto={AmodKumar} memberName="Amod Kumar" memberProfile="BusinessMan"/>
                <TeamCard memberPhoto={KumarShivam} memberName="Dr Kumar Shivam" memberProfile="Doctor (AIIMS)"/>
                <TeamCard memberPhoto={SudhanshuTrivedi} memberName="Sudhanshu Trivedi" memberProfile="Adv - Supreme Court"/>
                <TeamCard memberPhoto={SwatiSuman} memberName="Swati Suman" memberProfile="Officer - Metro Railways"/>
                <TeamCard memberPhoto={DhyanishKhanna} memberName="Dhyanish Khanna" memberProfile="Software Developer"/>
                <TeamCard memberPhoto={AnupamShrivastav} memberName="Anupam Shrivastav" memberProfile="Project Manager"/>
                <TeamCard memberPhoto={DeepuRai} memberName="Dr Deepu Rai" memberProfile="Physiotherapist"/>
                <TeamCard memberPhoto={AkhileshKumar} memberName="Dr Akhilesh Kumar" memberProfile="Dentist (BDS)"/>
                <TeamCard memberPhoto={JaiPrakash} memberName="Jay Prakash Singh" memberProfile="Enterprenur"/>
                <TeamCard memberPhoto={PradeepKumar} memberName="Pradeep Kumar" memberProfile="Social Worker"/>
                <TeamCard memberPhoto={RaviRanjan} memberName="Ravi Ranjan" memberProfile="Engineer"/>
                <TeamCard memberPhoto={AshishRanjan} memberName="Ashish Ranjan" memberProfile="Adv - Civil Court"/>
                <TeamCard memberPhoto={NitishKumar} memberName="Nitish Kumar" memberProfile="Mechanical Engineer"/>
                <TeamCard memberPhoto={ShubhamKumar} memberName="Shubham Kumar" memberProfile="Electrical Engineer"/>
                <TeamCard memberPhoto={AnupKumar} memberName="Anup Kumar" memberProfile="Social Worker"/>
                <TeamCard memberPhoto={VivekPathak} memberName="Vivek Pathak" memberProfile="HSE Officer"/>
                <TeamCard memberPhoto={NileshMishra} memberName="Nilesh Mishra" memberProfile=""/>
                <TeamCard memberPhoto={RanjitSrivastva} memberName="Ranjit Srivastva" memberProfile="QA/QC Engineer - Samsung"/>
                <TeamCard memberPhoto={SachinSingh} memberName="Sachin Singh" memberProfile="Electrical Technicial"/>
                <TeamCard memberPhoto={ArjunSingh} memberName="Arjun Singh" memberProfile="Enterpreneur"/>
                <TeamCard memberPhoto={ShubhamSingh} memberName="Shubham Singh" memberProfile="BusinessMan"/>
                <TeamCard memberPhoto={VishalKumar} memberName="Vishal Kumar" memberProfile="BusinessMan"/>
            </div>
        </div>
    )
}

export default Team