import React from 'react'
import Logo from './images/Logo.jpg'
// import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="container-fluid d-flex align-items-center bg-gradient text-dark" style={{backgroundColor:"yellowgreen"}}>
            <div className="m-2">
                <img src={Logo} className="rounded shadow my-2" height="70vh" alt="..." />
            </div>
            <div className="my-auto mx-4 text-center">
                <span className="fs-2 fw-bold">Suraksha Social Welfare Trust</span>
            </div>
        </div>

        // <div className="container-fluid bg-gradient text-center text-dark" style={{ backgroundColor: "yellowgreen" }}>
        //     <div className="row p-2 my-auto">
        //         <div className="col-md-1">
        //             <img src={Logo} className="rounded" height="80vh" alt="..." />
        //         </div>
        //         <div className="col-md-10 my-auto">
        //             <span className="fs-1 fw-bold">Suraksha Social Welfare Trust</span>
        //         </div>
        //         <div className="col-md-1 my-auto">
        //         <button className="btn btn-lg btn-primary mx-lg-1"><Link className="nav-link" to="/donate">Donate</Link></button>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Header