import React from 'react'
import HelpDesk from './components/HelpDesk'

function ConstitutionalRemedies(props) {
    return (
        <div className="conatiner-fluid">
            <div className="row mx-1 bg-light">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">Constitutional Remedies</h1>
                    <hr />
                    <ul className="my-3">
                        <li className="my-3">Suraksha Social Welfare Trust is a non-profit organization dedicated to providing constitutional remedies to individuals and communities who have been deprived of their rights.</li>
                        <li className="my-3">We believe that access to justice is a fundamental right and that everyone should have equal access to legal remedies.</li>
                        <li className="my-3">Our mission is to provide legal representation and support to individuals and communities who have been victims of injustice.</li>
                        <li className="my-3">We strive to promote the rule of law and protect the fundamental rights of all individuals.</li>
                        <li className="my-3">We offer a range of programs, including:</li>
                        <ul>
                            <li>Legal Aid</li>
                            <li>Public Interest Litigation</li>
                            <li>Advocacy</li>
                        </ul>
                        <li className="my-3">We work with communities to ensure that their rights are protected, and justice is served.</li>
                        <li className="my-3">Our team comprises qualified and experienced lawyers, paralegals, and support staff. They work tirelessly to ensure that our clients receive the best legal representation and support.</li>
                    </ul>
                </div>
                <div className="col-md-6 p-4">
                    <HelpDesk category="Constitutional Remedies" showAlert={props.showAlert} />
                </div>
            </div>
        </div>
    )
}

export default ConstitutionalRemedies