import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import joinUs from './components/images/joinUs1.jpg'
import emailjs from '@emailjs/browser';

function Contact(props) {

    const form = useRef();

    const navigate = useNavigate();

    const [contact, setContact] = useState({
        contact_name: "",
        contact_phone_no: "",
        contact_email: "",
        contact_message: ""
    });

    const handleChange = (event) => {
        setContact(values => ({ ...contact, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const contactData = {
            contact_name: contact.contact_name,
            contact_phone_no: contact.contact_phone_no,
            contact_email: contact.contact_email,
            contact_message: contact.contact_message
        }
        const response = fetch(
            "https://surakshasocialwelfare-default-rtdb.firebaseio.com/contact_data.json",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(contactData)
            }
        );

        emailjs.sendForm('service_88b3kad', 'template_eax0ho3', form.current, 'l6X-Wi3LhAqNDnrXt')

        if (response) {
            props.showAlert("Thankyou for contacting us. Have a Nice Day !");
            navigate('/alert');
        }
    }

    return (
        <div className="container-fluid" style={{ minHeight: "76.5vh", backgroundImage: `url(${joinUs})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className="row">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">Contact Us</h1>
                    <hr />
                    <h6>Phone No:</h6><p>Vishal Kumar (+91 94304 00328), Jhunna Shrivastava(+91 99343 66769)</p>
                    <h6>Email ID:</h6><p> hope@surakshasocial.in</p>
                    <h6>Address:</h6><p> Suraksha Social Welfare Trust, Near Panchayat Bhawan, Nawada, Barauli, Gopalganj (Bihar)</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.090812467759!2d84.5359801150889!3d26.3882535888227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39931f8bd9a224cb%3A0xff7591a989b6479f!2z4KS44KWB4KSw4KSV4KWN4KS34KS-IOCkuOCli-CktuCksiDgpLXgpYfgpLLgpKvgpYfgpK_gpLAg4KSf4KWN4KSw4KS44KWN4KSfIOCkleCkvuCksOCljeCkr-CkvuCksuCkrw!5e0!3m2!1sen!2sin!4v1678101182442!5m2!1sen!2sin" className="rounded" width="100%" height="50%" title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="col-md-6 mt-5 py-4 px-2 bg-gradient rounded">
                    <form ref={form} onSubmit={handleSubmit} className="fw-bold">
                        <div className="form-group m-4">
                            <label htmlFor="contact_name" className="form-label badge bg-dark">Full Name</label>
                            <input type="text" className="form-control" id="contact_name" name="contact_name" placeholder="Enter your name" onChange={handleChange} value={contact.contact_name} required />
                        </div>
                        <div className="form-group m-4">
                            <label htmlFor="contact_phone_no" className="form-label badge bg-dark">Phone Number</label>
                            <input type="text" className="form-control" id="contact_phone_no" name="contact_phone_no" placeholder="Enter your contact no" onChange={handleChange} value={contact.contact_phone_no} required />
                        </div>
                        <div className="form-group m-4">
                            <label htmlFor="contact_email" className="form-label badge bg-dark">Email Address</label>
                            <input type="email" className="form-control" id="contact_email" name="contact_email" placeholder="Enter your email id" onChange={handleChange} value={contact.contact_email} required />
                        </div>
                        <div className="m-4">
                            <label htmlFor="contact_message" className="form-label badge bg-dark">Message</label>
                            <textarea rows="3" className="form-control" id="contact_message" name="contact_message" placeholder="Enter your message here" onChange={handleChange} value={contact.contact_message} required />
                        </div>
                        <div className="form-group m-4">
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact