import React from 'react'
import Carousel from './components/Carousel'
import ServiceCard from './components/ServiceCard'
import healthCard from './components/images/Services/healthCard.jpg'
import educationCard from './components/images/Services/educationCard.jpg'
import employmentCard from './components/images/Services/employmentCard.jpg'
import womenEmpowermentCard from './components/images/Services/womenEmpowermentCard.jpg'
import constitutionalRemedyCard from './components/images/Services/constitutionalRemedyCard.jpg'
import socialAwarenessCard from './components/images/Services/socialAwarenessCard.jpg'
import About from './About'
import Mission from './Mission'
import AwardSlider from './components/MediaSlider'
import MediaSlider from './components/AwardSlider'

function Home() {
  return (
    <>
      <Carousel />
      <About />
      <Mission />
      <div className="container-fluid" style={{backgroundColor:"darkgreen"}}>
        <div className="row p-2 p-lg-5">
        <h1 className="text-center text-light mt-2">OUR SERVICES</h1>
        <hr className="m-0 text-light"/>
          <ServiceCard category="Health" categoryLink="health" cardImage={healthCard} categoryDescription="Quality Healthcare is a fundamental right that should be available to everyone, regardless of their socio-economic status." />
          <ServiceCard category="Education" categoryLink="education" cardImage={educationCard} categoryDescription="Every child deserves access to quality education and the opportunities to reach their full potential." />
          <ServiceCard category="Employment" categoryLink="employment" cardImage={employmentCard} categoryDescription="Dedicated to creating employment opportunities for underprivileged individuals and communities. " />
          <ServiceCard category="Women Empowerment" categoryLink="womenEmpowerment" cardImage={womenEmpowermentCard} categoryDescription="Women should have equal access to opportunities and resources to enable them to reach their full potential." />
          <ServiceCard category="Constitutional Remedies" categoryLink="constitutionalRemedies" cardImage={constitutionalRemedyCard} categoryDescription="Justice is a fundamental right and that everyone should have equal access to legal remedies." />
          <ServiceCard category="Social Awareness" categoryLink="socialAwareness" cardImage={socialAwarenessCard} categoryDescription="We believe that through education and advocacy, we can create positive change and promote social justice." />
        </div>
      </div>
      <AwardSlider/>
      <MediaSlider/>
    </>
  )
}

export default Home