import React from 'react'
import HelpDesk from './components/HelpDesk'

function WomenEmpowerment(props) {
    return (
        <div className="conatiner-fluid">
            <div className="row mx-1 bg-light">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">Women Empowerment</h1>
                    <hr />
                    <ul className="my-3">
                        <li className="my-3">Suraksha Social Welfare Trust is a non-profit organization dedicated to empowering women and promoting gender equality.</li>
                        <li className="my-3">We believe that women should have equal access to opportunities and resources to enable them to reach their full potential.</li>
                        <li className="my-3">Our mission is to empower women by providing them with education, skills training, and support to help them become leaders and change-makers in their communities.</li>
                        <li className="my-3">We strive to promote gender equality and create a more inclusive and just society.</li>
                        <li className="my-3">We offer a range of programs, including:</li>
                        <ul>
                            <li>Educational and Skills Training</li>
                            <li>Economic Empowerment</li>
                            <li>Health and Well-being</li>
                            <li>Leadership Development</li>
                        </ul>
                        <li className="my-3">We work with women and girls from disadvantaged backgrounds, providing them with the tools and resources they need to succeed.</li>
                        <li className="my-3">Our team comprises qualified and experienced educators, trainers, health professionals, and support staff. They work tirelessly to ensure that our programs are effective and impactful.</li>
                    </ul>
                </div>
                <div className="col-md-6 p-4">
                    <HelpDesk category="Women Empowerment" showAlert={props.showAlert} />
                </div>
            </div>
        </div>
    )
}

export default WomenEmpowerment