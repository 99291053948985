import React from 'react'
import HelpDesk from './components/HelpDesk'

function Employment(props) {
    return (
        <div className="conatiner-fluid">
            <div className="row mx-1 bg-light">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">Employment</h1>
                    <hr />
                    <ul className="my-3">
                        <li className="my-3">Suraksha Social Welfare Trust is a non-profit organization dedicated to creating employment opportunities for underprivileged individuals and communities.</li>
                        <li className="my-3">We believe that access to decent work is a fundamental right that should be available to everyone, regardless of their socio-economic status.</li>
                        <li className="my-3">Our mission is to empower individuals and communities by providing them with the skills and resources they need to secure gainful employment.</li>
                        <li className="my-3">We strive to promote economic growth and social development through our programs.</li>
                        <li className="my-3">We offer a range of programs, including:</li>
                        <ul>
                            <li>Vocational Training</li>
                            <li>Entrepreneurship Development</li>
                            <li>Job Placement Services</li>
                        </ul>
                        <li className="my-3">We work with individuals from disadvantaged backgrounds, providing them with the tools and resources they need to succeed in the job market.</li>
                        <li className="my-3">Our team comprises qualified and experienced trainers, job coaches, and support staff. They work tirelessly to ensure that our programs are effective and impactful.</li>
                    </ul>
                </div>
                <div className="col-md-6 p-4">
                    <HelpDesk category="Employment" showAlert={props.showAlert} />
                </div>
            </div>
        </div>
    )
}

export default Employment