import React from 'react'
import HelpDesk from './components/HelpDesk'

function Health(props) {
    return (
        <div className="conatiner-fluid">
            <div className="row mx-1 bg-light">
                <div className="col-md-6 p-4">
                    <h1 className="m-2">HEALTH</h1>
                    <hr />
                    <ul className="my-3">
                        <li className="my-3">Suraksha Social Welfare Trust is a non-profit organization dedicated to improving the health and wellbeing of people in need.</li>
                        <li className="my-3">We believe that access to quality healthcare is a fundamental right that should be available to everyone, regardless of their socio-economic status.</li>
                        <li className="my-3">Our mission is to provide healthcare services to underserved communities and promote public health awareness through education and advocacy.</li>
                        <li className="my-3">We strive to empower individuals and communities to take control of their health and well-being.</li>
                        <li className="my-3">Our Services [NGO Name] provides a range of healthcare services, including:</li>
                        <ul>
                            <li>Medical Checkups</li>
                            <li>Diagnostics Tests</li>
                            <li>Treatment</li>
                            <li>Rehabilitation</li>
                        </ul>
                        <li className="my-3">We also conduct health camps and community outreach programs to reach out to remote and marginalized populations.</li>
                        <li className="my-3">Our Team Our team comprises qualified and experienced healthcare professionals, volunteers, and support staff. They work tirelessly to provide quality care to our patients and improve public health.</li>
                    </ul>
                </div>
                <div className="col-md-6 p-4">
                    <HelpDesk category="Health" showAlert={props.showAlert} />
                </div>
            </div>
        </div>
    )
}

export default Health