import React from 'react'

function Alert(props) {
  return (
    <div className="container">
    <div className="d-flex flex-column align-items-center justify-content-center my-4 p-4 rounded bg-gradient text-center" style={{backgroundColor:"yellowgreen", minHeight:"71vh"}}>
        <h2>{props.alert}</h2>
    </div>
    </div>
  )
}

export default Alert