import React from 'react'
// import AboutCard from './components/AboutCard'
// import healthCard from './components/images/healthCard.jpg'
// import educationCard from './components/images/educationCard.jpg'
// import employmentCard from './components/images/employmentCard.jpg'
// import womenEmpowermentCard from './components/images/womenEmpowermentCard.jpg'
// import constitutionalRemedyCard from './components/images/constitutionalRemedyCard.jpg'
// import socialAwarenessCard from './components/images/socialAwarenessCard.jpg'

function About() {
  return (
    <div className="container-fluid p-2 p-lg-5 bg-light bg-gradient">
      <div className="m-4">
        <h1>About Us</h1>
        Our NGO <b>Suraksha Social Welfare Trust</b> is a non-profit organization dedicated to promote social welfare and serving the needs of marginalized communities across the country.
        Our mission is to provide support and opportunities to those who need it the most, with a focus on:
        <ul>
          <li>Health</li>
          <li>Education</li>
          <li>Employment</li>
          <li>Women Empowerment</li>
          <li>Constitutional Remedies</li>
          <li>Social Awareness</li>
        </ul>
        Since our founding, we have worked tirelessly to create positive change in the lives of individuals and families in need.
        Through our various programs and initiatives, either directly through our own projects or in partnership with Government and local NGOs.
        We strive to empower individuals and communities to reach their full potential and improve their quality of life.
        Thank you for taking the time to learn more about us. We invite you to join us in our mission to create a better world for all.
      </div>
      {/* <div className="row mx-3">
        <AboutCard cardImage={healthCard} cardTitle="Health" cardText="We recognize the importance of good health in enabling individuals to thrive, and we offer a range of health services to promote well-being and prevent illness." />
        <AboutCard cardImage={educationCard} cardTitle="Education" cardText="We believe that education is the key to unlocking a brighter future for all individuals, and we strive to make education accessible and equitable for everyone." />
        <AboutCard cardImage={employmentCard} cardTitle="Employment" cardText="We are dedicated to supporting individuals in their pursuit of meaningful employment, recognizing that economic stability is essential for a fulfilling life." />
        <AboutCard cardImage={womenEmpowermentCard} cardTitle="Women Empowerment" cardText="We also believe in the power of women to effect positive change in their communities, and we work to empower women through education, training, and support." />
        <AboutCard cardImage={constitutionalRemedyCard} cardTitle="Constitutional Remedies" cardText="We recognize the importance of legal advice in protecting the rights and interests of individuals and communities, and we offer legal support and advice to those in need." />
        <AboutCard cardImage={socialAwarenessCard} cardTitle="Social Awareness" cardText="We believe that social awareness is crucial in creating a more just and equitable society, and we seek to raise awareness and promote action on important social issues." />
      </div> */}
    </div>
  )
}

export default About